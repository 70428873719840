import NeoAnalyse from "@/components/analyse";
import {getJurisdictionName} from "@/utils/functions";
import {DateTime} from "luxon";
import nLoader from "@/components/n-loader";
import {get} from "lodash";
import {JURISDICTION_MAP} from "../../utils/constants";
import Comments from "@/components/comments";
import neoSwitch from "@/components/toggle-switch";
import {mapGetters} from "vuex";
import axios from "@/axios";
import {EventBus} from "@/main.js";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";

export default {
    name: "corporate-records-sidepanel",
    components: {
        NeoAnalyse,
        nLoader,
        NeoComments: Comments,
        neoSwitch,
    },
    props: {
        domainCompanyDetailsSearchList: Array,
        fieldType: Object,
        selected: Number,
        searchedQueryKeyword: "",
        showMoreInfo: {
            type: Boolean,
        },
        detail: {
            type: Object,
        },
        index: Number,
        parentIndex: {
            type: Number,
            default: null,
        },
        selectedQuery: {
            type: Object,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isTypehead: {
            type: Boolean,
            default: false,
        },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["getProduct", "getCaseId", "getSelectedToolQuery", "getReadOnlyMode", "getPermissions"]),
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },
        getResultClasses() {
            let classes = "";
            if (this.isOldVersionSelected) {
                if (this.detail.current_index === this.selected && this.isOldVersion) classes += "tw-bg-blue-50 tw-border-blue-600 hover:tw-bg-blue-50";
                else classes += "hover:tw-bg-blue-50";
            } else {
                if (this.detail.current_index === this.selected && !this.isOldVersion) classes += "tw-bg-blue-50 tw-border-blue-600 hover:tw-bg-blue-50";
                else classes += "hover:tw-bg-blue-50";
            }
            // if (!this.detail?.api_all_data?.history_count && !this.isOldVersion && this.isMonitoringTab)
            //     classes += ' tw-ml-2';

            if (this.selected || this.selected == 0) classes += " tw-flex-col";
            return classes;
        },
        dbCorpRecord() {
            return this.getPermissions.includes("db__corp_record");
        },
        dbResellerCorpRecord() {
            return this.getPermissions.includes("dnb_reseller__corp_record");
        },
    },
    mounted() {},
    methods: {
        get,
        getJurisdictionName,
        // incrementPage() {
        //     this.$emit("loadMoreCompanies");
        // },
        getAnalyserSource(key, matchKeyword, matchSource) {
            if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return matchSource || "name";
            else return matchSource ? "name" : "company_name";
        },

        getPreviousNames(detail) {
            return detail?.api_all_data?.company?.previous_names?.map((item) => item.company_name) || [];
        },

        checkPreviousNames(detail) {
            return detail.company_name && !detail.company_name.toLowerCase().includes(this.searchedQueryKeyword?.toLowerCase()) && this.getPreviousNames(detail).length > 0;
        },

        handleFetchMore(info) {
            // this.selected = info;
            this.$emit("moreInfo", true);
        },
        getJurisdictionFlag(code) {
            code = code.toLowerCase();
            code = code === "uk" ? "gb" : code;
            let country_code = code.split("_")[0];
            if (country_code.length > 2) {
                let country_name = JURISDICTION_MAP.find((ele) => ele.jurisdiction.code == country_code.toLowerCase() && !ele.flag_code)?.jurisdiction?.full_name;
                if (country_name) {
                    let flag_code = JURISDICTION_MAP.find((ele) => ele.jurisdiction.full_name == country_name && ele.jurisdiction.code !== country_code.toLowerCase());
                    if (flag_code?.jurisdiction?.code) {
                        country_code = flag_code?.jurisdiction?.code.toLowerCase();
                    }
                }
            }
            return this.$store.getters?.getIconsData?.find((e) => e.key == `flag_${country_code}`)?.icon_data ?? "";
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("EEE, MMM dd yyyy");
            return dt;
        },
        async showMoreData(detail, index) {
            // detail.loadingMore = true;
            // this.$forceUpdate();
            // this.selected = index;
            await this.$emit("showMoreData", {...detail, index: index, isOldVersion: this.isOldVersion});
            // detail.loadingMore = false;
            this.$forceUpdate();
        },
        async handleDone({target}, oldVersion) {
            let detail = this.detail;
            detail.api_all_data.changes_viewed = target.checked;
            let payload = [
                {
                    doc_id: detail.api_all_data.monitoring_id,
                    sources: [this.fetchKey(detail.key)],
                    cascaded: false,
                },
            ];
            EventBus.$emit("handleDone", {card: detail, index: this.index, oldVersion});
            this.$forceUpdate();
            await axios.post("/data-monitoring/mark-monitered-data-visited", {
                // sources: [this.fetchKey(detail.key)],
                // doc_id: detail.api_all_data.monitoring_id,
                card_info: payload,
                case_id: this.getCaseId,
                visited: target.checked,
            });
            this.updateComparisonReferences(detail);
        },

        fetchKey(sourceKey) {
            let corpKey = sourceKey;
            // if(key==='officer')
            switch (corpKey) {
                case "officer":
                    corpKey = "opencorporates";
                    break;
                case "officer_clarifiedBy":
                    corpKey = "clarifiedby";
                    break;
                case "officer_companyWatch":
                    corpKey = "companywatch";
                    break;
                case "officer_bvd":
                    corpKey = "bvd";
                    break;
                default:
                    break;
            }
            return corpKey.toLowerCase();
        },

        async handleMonitoring() {
            let detail = this.detail;
            detail.api_all_data.monitoring_status = !detail.api_all_data.monitoring_status;
            EventBus.$emit("handleMonitoring", {card: detail, index: this.index});
            this.$forceUpdate();
            let sourceKey = this.fetchKey(detail.key);
            try {
                await axios.post("/data-monitoring", {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.$route.params.id,
                    query: this.getSelectedToolQuery,
                    tool: this.$route.params.toolName,
                    frequency: 7,
                    monitoring_type: "card",
                    inherit_product_settings: false,
                    inherit_product_id: "",
                    card_id: detail.api_all_data.doc_id,
                    card_source: sourceKey.toLowerCase(),
                    status: detail.api_all_data.monitoring_status ? "enabled" : "disabled",
                });
                this.$toast.success("Monitoring updated successfully !");
            } catch (error) {
                this.$toast.error("Something Went Wrong !");
            }
        },

        getBackgroundStatus,
        getResultsID(index) {
            return `result-${index}`;
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        handleResultSaveData(detail) {
            if (!this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex !== detail.api_all_data.doc_id) EventBus.$emit("handleResultCorporateSave", detail);
        },

        updateComparisonReferences(data) {
            EventBus.$emit("updateMonitoringCascadingTimeline", {
                newData: data,
            });
        },
    },
};
